require('./scss/main.scss');


// import 'bootstrap/js/dist/alert.js';
// import 'bootstrap/js/dist/button.js';
// import 'bootstrap/js/dist/carousel.js';
import 'bootstrap/js/dist/collapse.js'; // required for responsive navbar at least
// import 'bootstrap/js/dist/dropdown.js'; // required for responsive navbar at least
// import 'bootstrap/js/dist/index.js';
import 'bootstrap/js/dist/modal.js';
// import 'bootstrap/js/dist/popover.js';
// import 'bootstrap/js/dist/scrollspy.js';
import 'bootstrap/js/dist/tab.js';
import 'bootstrap/js/dist/tooltip.js';
// import 'bootstrap/js/dist/util.js'; // required for responsive navbar at least

import 'select2/dist/js/select2.full.min.js';
import 'select2/dist/css/select2.css';

if (module.hot) {
    module.hot.accept();
}

$(document).ready(function() {
    $(".close-cookies-alert").click(function () {
        $.ajax({
            url: acceptCookiesUrl,
            success: function () {
                $('body').addClass('cookies-accepted');
            }
        });
    });
});